import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./style.scss";

function Why_us_training({ className, ...restProps }) {
  return (
    <section className="why_choose_us">
      <Container>
        <Row>
          <Col sm={12} md={12} lg={5} xl={5} xxl={5}>
            <div className="why_choose_img">
              <img
                src="assets/img/about/about-training.png"
                alt=""
                className="about_image"
              />
            </div>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={7}
            xl={7}
            xxl={7}
            className="know_more_home_div"
          >
            <p className="know_more_home">
              Know More About Our Industrial Training
            </p>
            <h2 className="why_choose_title">WHY CHOOSE US</h2>
            <p className="infor_why_choose_us_p">
              Empower your future with our training program - where knowledge
              meets hands-on experience, setting you on a path of success in the
              world of IT Beyond education, we offer an experience. Choose our
              training program for a journey that transforms not just what you
              know but who you are as a professional
            </p>
            <div className="icon_infor_why">
              <div className="icon">
                <i className="fa fa-user" aria-hidden="true"></i>
              </div>
              <div className="infor_why_choose_us">
                <h3 className="why_choose_title_1">Experienced Leader</h3>
                <p className="infor_why_choose_us_p">
                  Our team of expert leaders brings a wealth of experience and a
                  passion for mentorship to ensure you receive the best guidance
                  throughout your training. Along with one-on-one interactions,
                  feedback sessions, and career advice from those who have
                  walked the same path.
                </p>
              </div>
            </div>
            <div className="icon_infor_why">
              <div className="icon">
                <i className="fa fa-clock"></i>
              </div>
              <div className="infor_why_choose_us">
                <h3 className="why_choose_title_1">Placement assistance</h3>
                <p className="infor_why_choose_us_p">
                  Our commitment to your success doesn't end with the training.
                  Our placement assistance program is designed to empower you
                  with the tools, knowledge, and connections needed to launch
                  your career confidently. Leaders are there to guide you for
                  further placement opportunities.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Why_us_training;
