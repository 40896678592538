import React from "react";
import { Container } from "react-bootstrap";
import { dsnCN } from "../../hooks/helper";
import "./style.scss";
import email from "../../assets/img/e-mail.png";

function Copyright({ className, ...restProps }) {
  return (
    <div className={dsnCN("copyright", className)} {...restProps}>
      <Container>
        <div className="copyright_flex d-flex justify-content-center">
          <div className="text-center">
            <p>
              Copyright © {new Date().getFullYear()} All Rights Reserved
              CybernauticsTech
            </p>
          </div>
          <a
            href="/contact"
            className="btn btn-primary contact nav-link btn-fixed"
          >
            <img src={email} />
          </a>
          <a
            href="javascript:void(0)"
            className="btn btn-primary contact nav-link btn-fixed footer-secondicn"
          >
            Hire Me
          </a>
        </div>
      </Container>
    </div>
  );
}

export default Copyright;
