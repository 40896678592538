import React from "react";
import '../../index.css';
import {Helmet} from "react-helmet";
import Footer from "../../components/footer/Footer";

import Header from "../../components/new-header/Header";
import Banner from "../../components/account-page/Banner";
import Expert from "../../components/account-page/Expert";
import Contact from "../../components/contact-form/Contact";


const Accounts = () => {


return (
<React.Fragment>
    <Helmet>
        <title>CybernauticsTech - Accounts Page </title>
    </Helmet>
<Header />

{/* banner_start */}
<Banner/>
{/* end */}

{/* expert start */}
<Expert />
{/* expert end */}

{/* contact form start */}
<Contact /> 
{/* contact form end */}

<Footer />

</React.Fragment>

);
}



export default Accounts;


