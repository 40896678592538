import React from "react";
import { Row, Col, Container, Button } from 'react-bootstrap';
import './style.scss';


function Placement({ className, ...restProps }) {
    return(
        <div className="whychooseus_ment placement_services">
            <Container>
                <Row>
                    <Col className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <h4 className="whychooseus_h4">Invest in yourself, Elevate your skills</h4>
                        <h2 className="whychooseus_h2">Guidelines for secure future</h2>
                        <div className="whychooseus">
                            <div className="whychooseus_image blog_image">
                                <img src="/assets/img/gallery/training/modern_01.png"/>
                            </div>
                            <div className="whychooseus_text">
                                <div className="whychooseus_text_gorp">
                                    <h2>Develop and enhance your skills</h2>
                                    <p>Technology is the brush, knowledge is the paint. Craft your masterpiece. Explore the realms of technology, where every click defines progress</p>
                                </div>
                            </div>
                            <div className="whychooseus_image blog_image">
                                <img src="/assets/img/gallery/training/modern_02.png"/>
                            </div>
                            <div className="whychooseus_text">
                                <div className="whychooseus_text_gorp">
                                    <h2>Practice and get mentored</h2>
                                    <p>Bridge the gap between education and employment, get personal mentorship with mock interviews – Get Placement Ready with us.</p>
                                </div>
                            </div>
                            <div className="whychooseus_image blog_image">
                                <img src="/assets/img/gallery/training/modern_03.png"/>
                            </div>
                            <div className="whychooseus_text">
                                <div className="whychooseus_text_gorp">
                                    <h2>Get yourself placement ready</h2>
                                    <p>Dream big, work hard, secure the dream  job with the right skills, you've always wanted.and shape your future.</p>
                                </div>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </div>                          
    )
}

export default Placement;