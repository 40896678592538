import React from "react";
// import './style.scss';
import {Container, Row, Col} from 'react-bootstrap';
import bg from "../../assets/img/service/bg.png";




function Banner({ className, ...restProps }) {
    return(
        <section className="About_banner banner_wrapper" style={{ backgroundImage: `url(${bg})` }}>
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <div className=" banner_section">
                            <h1>Accounts and Bookkeeping</h1>
                            <p>Delegate your accounting and bookkeeping tasks to us for precise and organised financial records. Our dedicated team ensures accuracy in handling your financial data, from ledgers to statements. Focus on your business while we keep your finances in order, providing valuable insights for informed decision-making. Simplify your financial management with our reliable and efficient accounts and bookkeeping services.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Banner