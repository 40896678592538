import React from 'react';
import Slider from "react-slick";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function RewardGallery() {
    var settings = {
      dots: true,
      arrows:false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: {
        delay: 10,
        disableOnInteraction: false,
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Slider {...settings}>       
        <div className="about-gallry-img">
            <img src="/assets/img/gallery/training/tr_01.png" />
        </div>      
        <div className="about-gallry-img">
            <img src="/assets/img/gallery/training/tr_02.png" />
        </div>
        <div className="about-gallry-img">
            <img src="/assets/img/gallery/training/tr_03.png" />
        </div>
        <div className="about-gallry-img">
            <img src="/assets/img/gallery/training/tr_04.png" />
        </div>
        <div className="about-gallry-img">
            <img src="/assets/img/gallery/training/tr_05.png" />
        </div>
        <div className="about-gallry-img">
            <img src="/assets/img/gallery/training/tr_06.png" />
        </div>
        <div className="about-gallry-img">
            <img src="/assets/img/gallery/training/tr_07.png" />
        </div>
        <div className="about-gallry-img">
            <img src="/assets/img/gallery/training/tr_08.png" />
        </div>
        <div className="about-gallry-img">
            <img src="/assets/img/gallery/training/tr_09.png" />
        </div> 
      </Slider>
    );
  }