import React from "react";
import './style.scss';
import '../../index.css';
import { Row, Col, Container, Button } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Footer from "../../components/footer/Footer";
import Header from "../../components/new-header/Header";
import Banner from "../../components/training-page/Banner";
import WhyUsTraining from "../../components/why_choose_us/Why-us-training";
import WhatWeDeliver from "../../components/services/what-we-deliver";
import Placement from "../../components/placement/placement";

import Contact from "../../components/contact-form/Contact";
import Rewardsgallery from "../../components/about_new/rewardsgallery";

const Training = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>CybernauticsTech - Industrial Training Page </title>
            </Helmet>
        <Header />

        <Banner/>

        <WhyUsTraining />

        <WhatWeDeliver />       

        <Rewardsgallery />

        <Placement />

        <Contact /> 

        <Footer />

        </React.Fragment>

    );
}



export default Training;


