import React from 'react';
import Form from 'react-bootstrap/Form';
import {dsnCN} from "../../hooks/helper";
import {Button} from "react-bootstrap";


function Newsletter({className, ...restProps}) {
    return (
        <div className={dsnCN('col-menu' ,className)} {...restProps}>
            <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="email" placeholder="Email address" />
      </Form.Group>
      <Form.Group>
      <Button type="submit"><img src='/assets/img/contact/Vector_icon.png' alt='' /></Button>
      </Form.Group>
      </Form>
        </div>
    );
}

export default Newsletter;
