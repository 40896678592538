import React from "react";
// import './style.scss';
import {Container, Row, Col} from 'react-bootstrap';
import bg from "../../assets/img/service/bg.png";




function Banner({ className, ...restProps }) {
    return(
        <section className="About_banner banner_wrapper" style={{ backgroundImage: `url(${bg})` }}>
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <div className=" banner_section">
                            <h1>Industrial Training</h1>
                            <p>Unlock your potential in the dynamic world of web development with our comprehensive Industrial Training Program. Designed for aspiring developers and IT enthusiasts, this hands-on program provides a solid foundation in the latest web technologies and industry best practices</p>                           
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Banner