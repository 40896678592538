import React from "react";
import "./style.scss";

import { Container, Row, Col } from "react-bootstrap";

import SocialMedia from "../social-media/SocialMedia";
import ContentFooter from "./ContentFooter";
import ServicesFooter from "./ServicesFooter";
import Copyright from "./Copyright";
import { dsnCN } from "../../hooks/helper";
import Newsletter from "./Newsletter";
import footer_bg from "../../assets/img/footer-bg.jpg";
import bg from "../../assets/img/service/bg.png";
function Footer({ className, ...restProps }) {
  return (
    <>
      <section
        className="newsletter-ftr-section"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div className="dsn-col-footer footer-newsletter">
            <h4 className="footer-title">Subscribe to Our Newsletter</h4>
            <p className="text-center">
              Subscribe to get update, offers notification and information.
            </p>
            <Newsletter />
            {/* <h4 className="footer-title follow">Follow Us</h4> */}
          </div>
        </Col>
      </section>
      <footer
        className={dsnCN("footer", className)}
        {...restProps}
        style={{ backgroundImage: `url(${footer_bg})` }}
      >
        <Container>
          <Row>
            <Col sm={12} md={12} lg={3} xl={3} xxl={3}>
              <div className="dsn-col-footer ">
                <img src="/assets/img/logo-menu/logo.png" alt="" />
                <p>
                  We are a performance-driven team of artistic and creative
                  webheads that are always try to find innovative ways that are
                  inclined towards helping our clients to perform better within
                  the market
                </p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={3} xl={3} xxl={3}>
              <div className="dsn-col-footer contact_footer">
                <h4 className="footer-title">our IT Services</h4>
                <ServicesFooter />
              </div>
            </Col>

            <Col sm={12} md={12} lg={3} xl={3} xxl={3}>
              <div className="dsn-col-footer">
                <h4 className="footer-title">Get in Touch</h4>
                <ContentFooter />
                {/* <Newsletter /> */}
                {/* <h4 className="footer-title follow">Follow Us</h4> */}
              </div>
            </Col>
            <Col sm={12} md={12} lg={3} xl={3} xxl={3}>
              <div className="dsn-col-footer">
                <h4 className="footer-title">Follow us on</h4>
                {/* <ContentFooter /> */}
                <SocialMedia />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <Copyright className="pt-40 pb-40" />
    </>
  );
}

export default Footer;
