//import React from 'react';
import React, { useState, useEffect } from 'react';
import './style.scss';
import { Container, Navbar, NavbarToggle, NavLink, Nav } from 'react-bootstrap';
import Droplist from"./Droplist";
import downarrow from '../../assets/img/down-arrow.png';











function Header({ className, ...restProps }) {

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Add scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check the scroll position and update isScrolled state
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setIsScrolled(scrollTop > 0);
  };

return (
 <header className={`main_header ${isScrolled ? 'header-bg' : ''}`}>
  <Navbar bg="light" expand="lg">
    <Container>
      <div className='d-flex'>
        <Navbar.Brand href="/"><img src='/assets/img/logo-menu/logo.png' alt='' /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={()=> {window.location.href="/"}}>Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            {/* <Nav.Link href="/services">Services</Nav.Link> */}


            <div className="services_drop">
            <Nav.Link href="/services" >IT Services
            <img src={downarrow} />
            </Nav.Link>
           
            <div className="service_drop_wrapper">
                  <div className="service_drop_list">
                      <ul>
                      <li><a href="./graphics">Graphic Designing</a></li>
                        <li><a href="./design">Website Design</a></li>
                        <li><a href="./development">Website Development</a></li>
                        <li><a href="./qa">QA/ Manual Testing</a></li>
                        <li><a href="./seo">Digital Marketing</a></li>
                      </ul>
                  </div>
                </div>
               
              </div>
              {/* <Nav.Link href="/packages" >Packages
                
                </Nav.Link> */}
                  <Nav.Link href="/industrial-training">Industrial Training</Nav.Link>

                <Nav.Link href="/accounts">Accounts</Nav.Link>

                <Nav.Link href="/casestudies" >Case Studies
                
                </Nav.Link>


            

            {/* <Nav.Link href="/portfolio">Portfolio</Nav.Link> */}
         
            <Nav.Link href="#cyber_contact" className='btn btn-primary contact' >Get in Touch</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
        
      </div>
    </Container>
  </Navbar>
</header>

)
}


export default Header