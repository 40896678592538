import React, {useState, Suspense} from "react";
import {useSelector} from "react-redux";
import {Route, Routes} from "react-router-dom";
// import Package from "../components/package/Package";
import useEffectLocation from "../hooks/useEffectLocation";

import './style.scss';
import Accounts from "../pages/accounts/accounts";
import Training from "../pages/industrial-training/industrial-training";

// const Scrollbar = React.lazy(() => import("smooth-scrollbar"));

const MainDemo = React.lazy(() => import("../pages/home/MainDemo"));
const Demo2 = React.lazy(() => import("../pages/home/Demo2"));
const Demo3 = React.lazy(() => import("../pages/home/Demo3"));

const SliderOne = React.lazy(() => import("../views/slider/SliderOne"));
const SliderTow = React.lazy(() => import("../views/slider/SliderTow"));
const SliderThree = React.lazy(() => import("../views/slider/SliderThree"));
// const web_grid = React.lazy(() => import("../views/slider/web_grid"));
// const Work = React.lazy(() => import("../views/Work"));
// const WorkTow = React.lazy(() => import("../views/WorkTow"));
const ProjectDetails = React.lazy(() => import("../views/portfolio/ProjectDetails"));
const About = React.lazy(() => import("../pages/about/About"));
const Contact = React.lazy(() => import("../pages/contact/Contact"));
const Quotation_From = React.lazy(() => import("../pages/contact/Quotation_From"));
const Portfolio = React.lazy(() => import("../pages/portfolio/Portfolio"));
const Services = React.lazy(() => import("../pages/services/Services"));
const Design = React.lazy(() => import("../pages/design/Design"));
const Development = React.lazy(() => import("../pages/development/Development"));
const Cms = React.lazy(() => import("../pages/cms/Cms"));
const Seo = React.lazy(() => import("../pages/seo/Seo"));
const QA = React.lazy(() => import("../pages/qa/QA"));
const Graphics = React.lazy(() => import("../pages/graphics/Graphics"));
const CustomSolution = React.lazy(() => import("../pages/custom-solution/CustomSolution"));
const PortDesign = React.lazy(() => import("../pages/portdesign/PortDesigning"));
const CyberGallery = React.lazy(() => import("../pages/cyber-gallery/CyberGallery"));


// const Contact = React.lazy(() => import("../views/Contact"));
const BlogDetails = React.lazy(() => import("../views/blog/BlogDetails"));
const NovaraConic = React.lazy(() => import("../views/portfolio/NovaraConic"));
const Packages = React.lazy(() => import("../pages/packages/packages"));


const Casestudies = React.lazy(() => import("../pages/casestudies/Casestudies"));
const Beautytailor = React.lazy(() => import("../pages/casestudies/Items/beautytailor"));


const Router = () => {

    const [transPage, setTransPage] = useState("in");
    const scrollbar: React.MutableRefObject<null | Scrollbar> = useSelector(state => state.scrollbar);

    const location = useEffectLocation((l) => {
        setTransPage("out");
    })

    const onAnimateEnd = () => {
        if (transPage !== "out") return;
        scrollbar.current?.scrollTo(0, 0, 0);
        window.scrollTo({left: 0, top: 0});
        setTransPage("in");
    }


    return (

        <div id="dsn-content" className={`dsn-transition-page dsn-animate-${transPage}`}
             onAnimationEnd={onAnimateEnd}>
            <Suspense fallback={<div className="background-main h-100-v" />}>
                <Routes location={location}>

                    <Route path="/" element={<MainDemo/>}/>
                    <Route path="/demo-2" element={<Demo2/>}/>
                    <Route path="/demo-3" element={<Demo3/>}/>

                    <Route path="/slider" element={<SliderOne/>}/>
                    <Route path="/slider-2" element={<SliderTow/>}/>
                    <Route path="/slider-3" element={<SliderThree/>}/>
                    <Route path="/web-development" element={<web_grid/>}/>

                    <Route exact path="/service" element={<SliderOne/>}/>  
                    <Route exact path="/portfolio/:slug" element={<ProjectDetails/>}/>
                    <Route exact path="/about" element={<About/>}/>
                    <Route exact path="/services" element={<Services/>}/>
                    <Route exact path="/design" element={<Design/>}/>
                    <Route exact path="/development" element={<Development/>}/>
                    <Route exact path="/cms" element={<Cms/>}/>
                    <Route exact path="/seo" element={<Seo/>}/>
                    <Route exact path="/qa" element={<QA/>}/>
                    <Route exact path="/graphics" element={<Graphics/>}/>
                    <Route exact path="/custom-solutions" element={<CustomSolution/>}/>
                    <Route exact path="/Lorem-ipsum-Project" element={<PortDesign/>}/>
                    <Route exact path="/our-memories" element={<CyberGallery/>}/>

                    <Route exact path="/portfolio" element={<Portfolio/>}/>
                    <Route exact path="/contact" element={<Contact/>}/>
                    {/* <Route exact path="/contact" element={<Contact/>}/> */}
                    <Route exact path="/blog-details" element={<BlogDetails/>}/>
                    <Route exact path="*" element={<h1>Not Found</h1>}/>
                    <Route exact path="/DroowMenu" element={<NovaraConic/>}/>
                    <Route exact path="/packages" element={<Packages/>}/>
                    <Route exact path="/quotation_from" element={<Quotation_From/>}/>
                    <Route exact path="/casestudies" element={<Casestudies/>}/>
                    <Route exact path="/accounts" element={<Accounts/>}/>
                    <Route exact path="/industrial-training" element={<Training/>}/>
                    <Route exact path="/casestudies/beautytailor" element={<Beautytailor/>}/>
                </Routes>
            </Suspense>
        </div>


    );
}

export default React.memo(Router);