import React from "react";
// import './style.scss';
import {Container, Row, Col} from 'react-bootstrap';
import bg from "../../assets/img/service/bg.png";
import RewardGallery from '../about_new/rewards';


function Rewardsgallery({ className, ...restProps }) {
    return(
        <section className="new-gallery-rewards">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12} xl={12} xxl={12} >                        
                        <div className='cyber_content_heading'>                            
                            <h2>REWARDS AND RECOGNITION</h2>                            
                        </div>
                        <RewardGallery/>                                  
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Rewardsgallery;