import React from 'react';
import NavLink from "../button/NavLink";
import {dsnCN} from "../../hooks/helper";


function MenuFooter({className, ...restProps}) {
    const paginate = [
        {link: "/design", title: "Website Design"},
        {link: "/development", title: "Website Development"},
        {link: "/seo", title: "Digital Marketing"},
        {link: "/graphics", title: "Graphic Designing"},
        {link: "/qa", title: "QA/ Manual Testing"},
        {link:"#", title:"Industrial Training"},
      
    //    {link: "#", title: "Search Engine Optimization"},
       
    ];
    return (
        <ul className={dsnCN('col-menu', className)} {...restProps}>
            {
                paginate.map(
                    (item, i) =>
                        <li key={i}><NavLink to={item.link}>{item.title}</NavLink></li>
                )
            }
        </ul>
    );
}

export default MenuFooter;